import React, { useRef, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { joiResolver } from '@hookform/resolvers/joi'
import swal from 'sweetalert2'
import api from '../utils/axios'
import validator from '../validation'
import { Meta } from '../meta'
import { RatesModal } from './ratesmodal'
import { Investors } from './investors'
import { CorporateFaq } from './corporatefaq'
import { CorporateSlider } from './corporateslider'
import { CorporateTicketModel } from '../model'
import { PermissionModal } from './permissionmodal'
import { BussinessPartners } from './bussinesspartners'
import messageIcon from '../img/messageicon.webp'
import corporateImg from '../img/corporate-header.webp'
import nextIcon from '../img/next-icon.webp'
import feature1 from '../img/feature1.webp'
import feature2 from '../img/feature2.webp'
import feature3 from '../img/feature3.webp'

export const Corporate = () => {
  const metaTitle = 'Mundi - Şirketin için Mundi'
  const metaDescription =
    'Şirketin için yüksek getirili yatırım fırsatları Mundi’de. Hemen Başvur.'
  Meta(metaTitle, metaDescription)

  const handleClick = (event) => {
    event.preventDefault()
    setOpenModal(true)
  }

  const campaign = useRef<HTMLInputElement>(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CorporateTicketModel>({
    resolver: joiResolver(validator.coporateTicketSchema),
  })

  const onSubmit = async (data, e) => {
    const corporateBody = `
    Kurumsal Başvuru Formu
    Telefon: ${data.phoneNumber}
    Vergi Kimlik Numarası: ${data.vkn}
    Şirket Ünvanı: ${data.corporateName}
    Ad: ${data.firstName}
    Soyad: ${data.lastName}
    E-Posta: ${data.email}
    Ticari İleti İzni: ${campaign.current?.checked === true ? 'Evet' : 'Hayır'}
    ${aggregatorCodeValue ? `Kaynak: ${aggregatorCodeValue}` : ''}
    `

    const model = {
      body: corporateBody,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      subject: 'Kurumsal',
      fieldId: 6958981399837,
      fieldValue: 'kurumsal',
    }

    await api.gateway
      .post('/v1/crm/addTicket', JSON.stringify(model))
      .then((response) => {
        if (response.status === 200) {
          e.target.reset()
          return swal.fire({
            title: 'Mesajını aldık!',
            text: 'Kısa süre içerisinde seninle iletişime geçeceğiz.',
            imageUrl: `${messageIcon}`,
            imageWidth: 300,
            confirmButtonText: 'Tamam',
          })
        }
        return swal.fire({
          imageUrl: `${messageIcon}`,
          imageWidth: 300,
          title: 'Bir hata oluştu!',
          confirmButtonText: 'Tamam',
        })
      })
      .catch((error) => {
        if (
          error.response.status === 422 &&
          error.response.data.error.details[0].message
        ) {
          e.target.reset()
          return swal.fire({
            imageUrl: `${messageIcon}`,
            imageWidth: 300,
            title: `${error.response.data.error.details[0].message}`,
            confirmButtonText: 'Tamam',
          })
        }
        return swal.fire({
          imageUrl: `${messageIcon}`,
          imageWidth: 300,
          title: 'Bir hata oluştu!',
          confirmButtonText: 'Tamam',
        })
      })
  }

  const [openModal, setOpenModal] = useState(false)
  const [permissionKey, setPermissionKey] = useState(0)
  const [aggregatorCodeValue, setAggregatorCodeValue] = useState<null | string>(
    null
  )

  const handleClickForm = (event, key) => {
    event.preventDefault()
    setOpenModal(true)
    setPermissionKey(key)
  }

  const getAggregatorCodeValue = () => {
    const param = new URLSearchParams(window.location.search)
    const urlValue = param.get('aggregator_code') || null
    if (urlValue) {
      sessionStorage.setItem('aggregator_code', urlValue)
      return urlValue
    }
    const storedValue = sessionStorage.getItem('aggregator_code')
    return storedValue || null
  }

  useEffect(() => {
    setAggregatorCodeValue(getAggregatorCodeValue())
  }, [])

  return (
    <>
      <section>
        <div className="container">
          <div className="row corporate-common-margin-top-bottom">
            <div className="col-lg-5 d-none d-sm-block corporate-header-image order-lg-last order-md-first order-sm-first text-center">
              <img
                className="img-fluid corporate-main-img"
                src={corporateImg}
                alt="mundi"
              ></img>{' '}
            </div>
            <div className="col-lg-7 order-lg-12 order-md-12 order-sm-12 my-auto">
              <h1 className="header-title mb-0">
                YENİ NESİL FİNTEK UYGULAMASI MUNDİ SAYESİNDE
              </h1>
              <div className="header-body">
                Şirketin için yüksek getirili{' '}
                <br className="d-xs-block d-sm-none d-xl-block" />
                yatırım fırsatları Mundi{"'"}de
              </div>
              <div className="row header-point-margin-top">
                <div className="row mx-auto d-flex justify-content-between justify-content-md-start">
                  <span className="col-4 col-sm-3 col-md-2 col-lg-3 col-xl-2 header-point">
                    Güvenli
                  </span>
                  <span className="col-4 col-sm-3 col-md-2 col-lg-3 col-xl-2 header-point header-point-middle-margin">
                    Masrafsız
                  </span>
                  <span className="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-2 header-point header-point-end-margin">
                    Kolay
                  </span>
                </div>
              </div>
              <div className="header-message">
                TL ve USD birikimlerini yetkili kurumlar üzerinden sermaye
                piyasası ürünlerinde güvenle değerlendir, hesabında kalan
                bakiyeyi avantajlı oranlarla nemalandırma imkanı yakala.
              </div>
              <div className="mt-30-collapse-16">
                <a
                  className="apply-button standart-button fixedsize-button"
                  href="/kurumsal#CorporateForm"
                >
                  Hemen Başvur
                  <img className="next-icon" src={nextIcon} alt="next"></img>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr className="mb-0" />
      <section>
        <CorporateSlider />
      </section>
      <section id="GuncelNemaOranlari" className="bg1">
        <div className="container">
          <div className="row rates">
            <div className="col-sm-6">
              <div className="home-section3-body">
                <p>
                  <span className="home-section3-text">Getirini hesapla</span>
                </p>
              </div>
              <div className="home-section3-message">
                Uygulamayı indirerek getirini hemen hesaplayabilirsin.
              </div>
              <div className="mt-30-collapse-16 ">
                <div className="d-none d-sm-block d-flex align-items-center">
                  <button
                    type="button"
                    onClick={handleClick}
                    className="reserve-button text-center align-self-center text-nowrap"
                    data-bs-toggle="modal"
                    data-bs-target="#appModal"
                  >
                    Uygulamayı İndir
                  </button>
                </div>
                <div className="mb-3 d-block d-sm-none">
                  <button className="standart-button fullsize-button w-100">
                    <a
                      className="reserve-button nounderline text-center d-sm-none"
                      href="https://2ykh.adj.st?adj_t=1dkrt8yx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Uygulamayı İndir
                    </a>
                  </button>
                </div>
              </div>
            </div>
            <RatesModal />
            <div className="col-sm-6">
              <div className="home-section3-point-mt">
                <p className="home-section3-point">
                  Tutardan bağımsız <strong>herkese</strong> aynı oranda{' '}
                  <strong>yüksek getiri imkanı sağlar</strong>.
                </p>
                <p className="home-section3-point">
                  Gizli masraflar veya koşullar <strong>yoktur.</strong>
                </p>
                <p className="home-section3-point">
                  Banka hesaplarına hızlı ve{' '}
                  <strong>kolay para transferi</strong> gerçekleşir.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="SirketinIcinMundi">
        <div className="container">
          <div className="row m-auto features-title">
            <div className="col">
              <h2 className="features-body mb-0">Şirketin için Mundi</h2>
            </div>
          </div>
          <div className="row col-12 mx-auto features-card-wrapper">
            <div className="col-xs-12 col-sm-4 mb-4">
              <div className="card features-card-body h-100 border-0 mx-2">
                <div className="text-center">
                  <img
                    className="img-fluid card-img-top text-center corpotate-card-img"
                    src={feature1}
                    alt="mundi"
                  />
                </div>
                <div className="card-body card-body-margin-bottom">
                  <p className="card-title corporate-card-title">
                    Gecelik getiri <br className="d-none d-sm-block" />
                    kazan
                  </p>
                  <p className="card-text corporate-card-text">
                    Paran hesaplarda boşta beklemesin.{' '}
                    <br className="d-block d-sm-none" />
                    Günlük getiri ile vade beklemeden paranı değerlendir.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 mb-4">
              <div className="card features-card-body h-100 border-0 mx-2">
                <div className="text-center">
                  <img
                    className="img-fluid card-img-top text-center corpotate-card-img"
                    src={feature2}
                    alt="mundi"
                  />
                </div>
                <div className="card-body card-body-margin-bottom">
                  <p className="card-title corporate-card-title">
                    Dövizin <br className="d-none d-sm-block" />
                    erimesin
                  </p>
                  <p className="card-text corporate-card-text">
                    Şirketinin Amerikan Doları birikimleri için koşulsuz, cazip,
                    güvenli getiri imkanı yakala.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-4 mb-4">
              <div className="card features-card-body h-100 border-0 mx-2">
                <div className="text-center">
                  <img
                    className="img-fluid card-img-top text-center corpotate-card-img"
                    src={feature3}
                    alt="mundi"
                  />
                </div>
                <div className="card-body card-body-margin-bottom">
                  <p className="card-title corporate-card-title">
                    Hızlı ve kolay para <br className="d-none d-sm-block" />
                    transferi yap
                  </p>
                  <p className="card-text corporate-card-text">
                    Mundi’den dilediğin banka hesabına masrafsız TL ve USD para
                    transferi yap.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-120-collapse-16">
            <div className="container">
              <div className="col-xs-12 col-sm-4 offset-sm-4 pl-20 pr-20">
                <a
                  className="standart-button fullsize-button apply-button d-sm-none"
                  href="https://2ykh.adj.st?adj_t=1dkrt8yx"
                  target="_blank"
                  rel="noreferrer"
                >
                  Uygulamayı İndir
                </a>
                <button
                  type="button"
                  onClick={handleClick}
                  className="standart-button fullsize-button apply-button d-none d-sm-block"
                  data-bs-toggle="modal"
                  data-bs-target="#appModal"
                >
                  Uygulamayı İndir
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg1">
        <BussinessPartners />
      </section>
      <section>
        <Investors />
      </section>
      <section id="CorporateForm" className="bg1">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 mx-auto text-center">
              <h2 className="support-header mb-0">Kurumsal Başvuru</h2>
              <p className="support-text text-center">
                Şirketin için hemen Mundi hesabı açma başvurusu yapabilirsin.
              </p>
            </div>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="row mx-auto">
              <div className="col-sm-8 justify-content-center offset-sm-2">
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">
                    AD
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    {...register('firstName', { required: true })}
                  />
                </div>
                {errors.firstName && (
                  <p>
                    <span className="error">Bu alan zorunludur.</span>
                  </p>
                )}
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">
                    SOYAD
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    {...register('lastName', { required: true })}
                  />
                </div>
                {errors.lastName && (
                  <p>
                    <span className="error">Bu alan zorunludur.</span>
                  </p>
                )}
                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="form-label">
                    CEP TELEFONU
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="phoneNumber"
                    {...register('phoneNumber', { required: true })}
                  />
                </div>
                {errors.phoneNumber && (
                  <p>
                    <span className="error">Bu alan zorunludur.</span>
                  </p>
                )}
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    E-POSTA ADRESİ
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    {...register('email', { required: true })}
                  />
                </div>
                {errors.email && (
                  <p>
                    <span className="error">{errors.email.message}</span>
                  </p>
                )}
                <div className="mb-3">
                  <label htmlFor="corporateName" className="form-label">
                    ŞİRKET ÜNVANI
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="corporateName"
                    {...register('corporateName', { required: true })}
                  />
                </div>
                {errors.corporateName && (
                  <p>
                    <span className="error">Bu alan zorunludur.</span>
                  </p>
                )}
                <div className="mb-3">
                  <label htmlFor="vkn" className="form-label">
                    VERGİ KİMLİK NO
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="vkn"
                    {...register('vkn', { required: true })}
                  />
                </div>
                {errors.vkn && (
                  <p>
                    <span className="error">Bu alan zorunludur.</span>
                  </p>
                )}
              </div>
              <div className="row mx-auto">
                <div className="col-sm-8 justify-content-center offset-sm-2">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="userAgreement"
                      {...register('userAgreement', { required: true })}
                    />
                    <label className="form-check-label" htmlFor="userAgreement">
                      <a
                        className="form-check-link"
                        onClick={(event) => handleClickForm(event, 4)}
                        data-bs-toggle="modal"
                        data-bs-target="#permissionModal"
                        href="/"
                      >
                        Kullanıcı Sözleşmesi
                      </a>
                      {"'"}ni okudum ve onaylıyorum.
                    </label>
                  </div>
                  {errors.userAgreement && (
                    <p>
                      <span className="error">Bu alan zorunludur.</span>
                    </p>
                  )}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="kvkk"
                      {...register('kvkk', { required: true })}
                    />
                    <label className="form-check-label" htmlFor="kvkk">
                      Kişisel verilerimin işlenmesine ilişkin{' '}
                      <a
                        className="form-check-link"
                        onClick={(event) => handleClickForm(event, 1)}
                        data-bs-toggle="modal"
                        data-bs-target="#permissionModal"
                        href="/"
                      >
                        Aydınlatma Metnini
                      </a>{' '}
                      okudum ve anladım.
                    </label>
                  </div>
                  {errors.kvkk && (
                    <p>
                      <span className="error">Bu alan zorunludur.</span>
                    </p>
                  )}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="share-permit"
                      {...register('permission', { required: true })}
                    />
                    <label className="form-check-label" htmlFor="share-permit">
                      <a
                        className="form-check-link"
                        href="/"
                        onClick={(event) => handleClickForm(event, 2)}
                        data-bs-toggle="modal"
                        data-bs-target="#permissionModal"
                      >
                        Aydınlatma Metnini
                      </a>{' '}
                      de dikkate alarak Açık Rıza`nız kapsamında kişisel
                      verilerinizin Mudi Finansal Teknoloji Hizmetleri A.Ş.
                      tarafından belirtilen yurtdışındaki iş ortakları,
                      tedarikçilere aktarılması amacıyla işlenmesine onay
                      veriyorsanız işaretleyiniz.
                    </label>
                  </div>
                  {errors.permission && (
                    <p>
                      <span className="error">Bu alan zorunludur.</span>
                    </p>
                  )}
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="campaign"
                      ref={campaign}
                    />
                    <label className="form-check-label" htmlFor="campaign">
                      Mudi Finansal Teknoloji Hizmetleri A.Ş. tarafından benimle
                      e-posta yoluyla{' '}
                      <a
                        className="form-check-link"
                        href="/"
                        onClick={(event) => handleClickForm(event, 3)}
                        data-bs-toggle="modal"
                        data-bs-target="#permissionModal"
                      >
                        Ticari Elektronik İleti İzni
                      </a>{' '}
                      kapsamında kampanyalarımız hakkında iletişime geçilmesine
                      izin veriyorum.
                    </label>
                  </div>
                  <PermissionModal
                    openModal={openModal}
                    permissionKey={permissionKey}
                  />
                  <div className="row mb-120-collapse-16">
                    <div className="col-sm-8 mt-25 mlr-250"></div>
                    <button type="submit" className="form-button">
                      Gönder
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="faq-title mb-0">Sıkça Sorulan Sorular</h2>
              <CorporateFaq />
              <div className="row">
                <div className="text-center">
                  <p className="mt-55 mb-120">
                    <a
                      className="invitation-button-text nounderline"
                      href="/destek#Faq"
                    >
                      Daha Fazlası
                    </a>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
